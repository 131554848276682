import './index.scss';
import {Component} from "react";
import axios from 'axios';
import Pagination from "../../../components/pagination";
import {Link} from "react-router-dom";

class Blog extends Component {
    constructor(props) {
        super(undefined);
        console.log("Blog");
        this.baseUrl = 'http://47.107.166.94:8022/';
        this.state = {
            blog_type: '',
            type_list: [],
            blog_data: {},
            blog_list: [],
            blog_pages: {},
            pageCurr: 1,
            totalPage: 1,
            param:{
                blogType: 3
            }
        }
    }
    componentDidMount(){
        this.getAllBlogTag();
        this.getBlogByPage(1,{});
    }

    getAllBlogTag() {
        axios.get('/api/type/all')
            .then(res => {
                const data = res.data;
                this.setState({
                    type_list: data
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    getBlogByPage(page,param) {
        console.log(param);
        if (param !== {}) {
            this.setState({
                blog_type: param.blogType
            })
        }
        axios.get('/api/blog/all',{
            params: {
                "page":page,
                "blogType": param==={}?'':param.blogType
            }
        }).then(res => {
                const data = res.data;
                this.setState({
                    blog_data: data,
                    blog_list: data.list,
                    pageCurr: data.pageNum,
                    totalPage: data.pages,
                    param: param
                })
            })
            .catch(err => {
                console.log(err);
            });
    }

    dateFormat(fmt, date) {
        date = this.stringToDate(date);
        let ret;
        const opt = {
            "y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            }
        }
        return fmt;
    }

    stringToDate(str){

        const tempStrs = str.split(" ");

        const dateStrs = tempStrs[0].split("-");

        const year = parseInt(dateStrs[0], 10);

        const month = parseInt(dateStrs[1], 10) - 1;

        const day = parseInt(dateStrs[2], 10);

        const timeStrs = tempStrs[1].split(":");

        const hour = parseInt(timeStrs [0], 10);

        const minute = parseInt(timeStrs[1], 10);

        const second = parseInt(timeStrs[2], 10);

        return new Date(year, month, day, hour, minute, second);
    }

    render() {

        return (
            <div id="blog" data-component = "Blog">
                <div id="blog_left">
                    <div className="head_center">
                        <div className="head_img">
                            <a href="/"><img src="/img/favicon.ico"/></a>
                        </div>
                        <div className="userName"><i>Mr.Zhou</i></div>
                    </div>
                    {/*博客类型列表*/}
                    <div className="sidebar">
                        <ul id="sidenav">
                            {
                                this.state.type_list.map((item) => {
                                    return (<li key={item.typeId}
                                                onClick={this.getBlogByPage.bind(this, 1, {"blogType": item.typeId})}
                                                className={item.typeId === this.state.blog_type ? "label_li label_li_active" : "label_li"}>{item.typeName}<span
                                        className="label_num">{item.typeNum}</span></li>)
                                })
                            }
                        </ul>
                    </div>
                    <div className="message-board">

                    </div>
                </div>
                <div id="blog_right">
                    {/*页头工具栏*/}
                    <div className="blogList-header">
                        <div className="blog_editor">
                            <div>
                                {/*<Link to={"/header/editor"}>写博客</Link>*/}
                                <div className="un-link"/>
                            </div>
                        </div>
                    </div>
                    {/*博客预览列表*/}
                    <div className="container-fluid" id="blog_box">
                        {
                            this.state.blog_list.map((item) => {
                                return (
                                    <div className='row' key={item.blogUuid}>
                                        <div className='blog_list'>
                                            <div className='blog_title'>
                                                {item.blogTitle}
                                            </div>
                                            <div className='blog_tip_box'>
                                                <div className='blog_tip'>
                                                    <span style={{marginRight: 20}}>作者：&nbsp;
                                                        <b>{item.userName}</b></span>
                                                    <span style={{marginRight: 20}}>点击：&nbsp;
                                                        <b>{item.pageView}</b></span>
                                                    <span style={{marginRight: 20}}>标签：&nbsp;
                                                        <b>{item.typeName}</b></span>
                                                    <span>日期：&nbsp;
                                                        <b>{this.dateFormat("yyyy年mm月dd日 HH时MM分SS秒", item.blogDate)}</b></span>
                                                </div>
                                            </div>
                                            <div className='span12 blog_content'>
                                                <div className='blog_preview'>
                                                    <p>{item.blogSummary}</p>
                                                </div>
                                            </div>
                                            <div className='blog_tip_box'>
                                                <div className='btn_all_content'>
                                                    <Link to={
                                                        {
                                                            pathname: '/user/content/' + item.blogUuid
                                                        }
                                                    }>阅读全文<b>>>></b></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {/*分页条*/}
                    {
                        this.state.blog_list.length > 0 ?
                            <div className="page-box">
                                <article>
                                    <Pagination config={{
                                        pageCurr: this.state.pageCurr,
                                        totalPage: this.state.totalPage
                                    }}
                                     param={this.state.param}
                                     cb={this.getBlogByPage.bind(this)}
                                    />
                                </article>
                            </div>
                            : null
                    }
                </div>
            </div>
        );
    }
}

export default Blog;
